.rc-card-field {
  font-family: "Space Grotesk", sans-serif !important;
  box-shadow: 0 0 0 0.0625rem #E2E7F4 inset;
  height: 3.5rem;
  padding: 1rem;
  border-radius: 0.75rem;
  -webkit-transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;

  &--focused {
    box-shadow: 0 0 0 0.125rem #275ADB inset !important;
  }

  &--invalid {
    box-shadow: 0 0 0 0.125rem #ff4e64 inset !important;
  }

  &:hover {
    box-shadow: 0 0 0 0.125rem #181B1C12 inset, 0 0 0 0.125rem #E2E7F4 inset;
  }
}
