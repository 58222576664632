:root.theme-travel-light,
.theme-travel-light {
  --piccolo: 39 90 219; /* #275adb */
  --hit: 252 233 176; /* #fce9b0 */
  --beerus: 226 231 244; /* #E2E7F4 */
  --gohan: 240 243 251; /* ##F0F3FB */
  --goten: 255 255 255; /* #ffffff */
  --goku: 255 255 255; /* #ffffff */
  --bulma: 12 12 12; /* #0C0C0C */
  --trunks: 165 173 188; /* #A5ADBC */
  --popo: 0 0 0; /* #000000 */
  --jiren: 39 90 219 / 0.12; /* #275ADB1F */
  --heles: 24 27 28 / 0.08; /* #181B1C14 */
  --zeno: 24 27 28 / 0.4; /* #181B1C66 */

  /* support-colors  */
  --krillin: 255 179 25; /* #ffb319 */
  --krillin-60: 255 179 25 / 0.56; /* #ffb319 */
  --krillin-10: 255 179 25 / 0.08; /* #ffb319 */

  --chichi: 255 78 100; /* #ff4e64 */
  --chichi-60: 255 78 100 / 0.56; /* #ff4e64 */
  --chichi-10: 255 78 100 / 0.08; /* #ff4e64 */

  --roshi: 74 209 95; /* #4ad15f */
  --roshi-60: 74 209 95 / 0.56; /* #4ad15f */
  --roshi-10: 74 209 95 / 0.08; /* #4ad15f */

  --dodoria: 211 48 48; /* #d33030 */
  --dodoria-60: 211 48 48 / 0.56; /* #d33030 */
  --dodoria-10: 211 48 48 / 0.08; /* #d33030 */

  --cell: 149 241 213; /* #95f1d5 */
  --cell-60: 149 241 213 / 0.56; /* #95f1d5 */
  --cell-10: 149 241 213 / 0.08; /* #95f1d5 */

  --raditz: 179 128 74; /* #b3804a */
  --raditz-60: 179 128 74 / 0.56; /* #b3804a */
  --raditz-10: 179 128 74 / 0.08; /* #b3804a */

  --whis: 52 72 240; /* #3448f0 */
  --whis-60: 52 72 240 / 0.56; /* #3448f0 */
  --whis-10: 52 72 240 / 0.08; /* #3448f0 */

  --frieza: 92 51 207; /* #5c33cf */
  --frieza-60: 92 51 207 / 0.56; /* #5c33cf */
  --frieza-10: 92 51 207 / 0.08; /* #5c33cf */

  --nappa: 114 85 80; /* #725550 */
  --nappa-60: 114 85 80 / 0.56; /* #725550 */
  --nappa-10: 114 85 80 / 0.08; /* #725550 */

  /* border-radius */
  --radius-i-xs: 0.25rem; /* 4px */
  --radius-i-sm: 0.5rem; /* 8px */
  --radius-i-md: 0.75rem; /* 12px */
  --radius-s-xs: 0.25rem; /* 4px */
  --radius-s-sm: 0.5rem; /* 8px */
  --radius-s-md: 0.75rem; /* 12px */
  --radius-s-lg: 1rem; /* 16px */

  /* border-width */
  --border-width: 0.0625rem; /* 1px */
  --border-i-width: 0.125rem; /* 2px */

  /* opacity */
  --opacity-moon: 0.6;

  /* fonts */
  --averta: 'Averta Std';
  --dm-sans: 'DM Sans';

  /* box-shadow */
  --shadow-xs: 0 4px 12px -6px rgb(0 0 0 / 0.06);
  --shadow-sm: 0 6px 6px -6px rgb(0 0 0 / 0.16), 0 0 1px rgb(0 0 0 / 0.4);
  --shadow-md: 0 12px 12px -6px rgb(0 0 0 / 0.16), 0 0 1px rgb(0 0 0 / 0.4);
  --shadow-lg: 0 8px 24px -6px rgb(0 0 0 / 0.16), 0 0 1px rgb(0 0 0 / 0.4);
  --shadow-xl: 0 32px 32px -8px rgb(0 0 0 / 0.08),
    0 0 32px -8px rgb(0 0 0 / 0.12), 0 0 1px rgb(0 0 0 / 0.2);
}
