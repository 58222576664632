#root {
  display: flex;
  height: 100dvh;

  > main {
    flex-grow: 1;
    flex-basis: auto;
  }

  font-family: "DM Sans", sans-serif !important;

  h1, h2 {
    font-family: "Space Grotesk", sans-serif !important;
    margin: 0;
    margin-top: 0;
    margin-bottom: 0;
    letter-spacing: -0.04rem;
  }
}
