.date-range-picker {
  &--mobile {
    .rdrWeekDays {
      border-bottom: 1px solid #E2E7F4;
    }

    .rdrMonthAndYearWrapper {
      height: 40px;
      padding: 0 10px;

      &::after {
        font-weight: 500;
        font-size: 20px;
        line-height: 26.04px;
        color: #0C0C0C;
        content: "Select dates";
      }
    }

    .rdrMonthName {
      font-size: 12px;
      text-align: left;
    }    
  }

  &--laptop {
    .rdrMonthName {
      font-size: 14px;
      text-align: center;
      margin-bottom: 24px;
    }
  
    .rdrMonthAndYearWrapper {
      height: 36px;
      padding: 0;
      position: absolute;
      width: calc(100% - 72px);
      top: 32px;
      left: 32px;
    }
  }
  
  .rdrMonthAndYearPickers {
    display: none;
  }

  .rdrWeekDays {
    width: calc(100% + 20px);
    padding: 12px 20px;
    margin-left: -10px;
    margin-bottom: 12px;
  }

  .rdrWeekDay {
    font-weight: 400;
    font-size: 16px;
    line-height: 20.83px;
    letter-spacing: -0.01em;
    color: #A5ADBC;
  }

  .rdrInfiniteMonths {
    overflow: hidden;
    height: auto !important;
  }

  .rdrMonthsHorizontal {
    gap: 48px;
  }

  .rdrNextPrevButton {
    background: transparent;
    width: 36px;
    height: 36px;

    &:hover {
      background: transparent;

      &::after {
        color: #0C0C0C;
      }
    }

    &::after {
      color: #A5ADBC;
      font-size: 24px;
    }

    i {
      display: none;
    }
  }

  .rdrPprevButton {
    &::after {
      content: "<";
    }
  }

  .rdrNextButton {
    &::after {
      content: ">";
    }
  }

  .rdrMonth {
    height: auto !important;
  }

  .rdrMonthName {
    font-weight: 400;
    color: #0C0C0C;
    letter-spacing: 0.16em;
    text-transform: uppercase;
  }

  .rdrDay {
    margin-bottom: 7.5px;
    height: 44.57px;
  
    .rdrStartEdge, .rdrEndEdge, .rdrInRange {
      height: 100%;
      top: 0;
    }

    .rdrStartEdge {
      border-top-left-radius: 999999px;
      border-bottom-left-radius: 999999px;
    }

    .rdrEndEdge {
      border-top-right-radius: 999999px;
      border-bottom-right-radius: 999999px;
    }

    .rdrDayNumber {
      height: 100%;
      top: 0;
    }

    &:not(.rdrDayPassive) {
      .rdrInRange + .rdrDayNumber {
        span {
          color: #0C0C0C !important;
        }
      }

      .rdrStartEdge + .rdrDayNumber, .rdrEndEdge + .rdrDayNumber {
        background-color: #0C0C0C !important;
        border-radius: 999999px;

        span {
          color: #fff !important;
        }
      }
    }
  }

  .rdrDayEndOfMonth, .rdrDayEndOfWeek {
    .rdrInRange,  .rdrStartEdge {
      border-top-right-radius: 999999px;
      border-bottom-right-radius: 999999px;
    }
  }

  .rdrDayStartOfMonth, .rdrDayStartOfWeek {
    .rdrInRange, .rdrEndEdge {
      border-top-left-radius: 999999px;
      border-bottom-left-radius: 999999px;
      left: 2px;
    }
  }

  .rdrDayToday .rdrDayNumber span:after {
    display: none;
  }
}