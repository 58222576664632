@import 'react-loading-skeleton/dist/skeleton.css';
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './theme';

html {
  width: 100%;
  height: 100%;
  overflow-y: auto !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  body {
    input, select:focus, textarea {
      font-size: 16px !important;
    }

    background-color: #ffffff;
    color: #2c2c2c;
    width: 100%;
    height: 100%;

    a {
      color: #2c2c2c;

      &:hover {
        color: #5b5b5b;
        text-decoration: none;
      }
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  button {
    border-style: none;
  }
}

br {
  height: 1.5rem !important;
  display: block !important;
  content: '' !important;
}

.Toastify__toast {
  font-size: 1rem !important;
}
